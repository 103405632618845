import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-privacy-and-cookie',
  templateUrl: './policy-privacy-and-cookie.component.html',
  styleUrls: ['./policy-privacy-and-cookie.component.css']
})
export class PolicyPrivacyAndCookieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
