import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { CareerComponent } from './career/career.component';
import { PolicyPrivacyAndCookieComponent } from './policy-privacy-and-cookie/policy-privacy-and-cookie.component';
import { ContactUSService } from './Services/contact-us.service';
import { ActiveJSService } from './Services/active-js.service';
import { CookieJSService } from './Services/cookie-js.service';
import { MarqueeService } from './Services/marquee.service';
import { AlertService } from './Services/alert.service';
import { GenericDataService } from './Services/generic-data.service';
import { GlobalVariablesService } from './Services/global-variables.service';
import { DialogConfirmComponent } from './Dialogs/dialog-confirm/dialog-confirm.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxCaptchaModule } from 'ngx-captcha';
import { HttpClientModule } from '@angular/common/http';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressRouterModule } from '@ngx-progressbar/router';
import { DeviceDetectorService } from 'ngx-device-detector';
@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        AboutUsComponent,
        ContactComponent,
        CareerComponent,
        PolicyPrivacyAndCookieComponent,
        DialogConfirmComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule,
        AppRoutingModule,
        NgProgressModule,
        NgProgressHttpModule,
        NgProgressRouterModule,

        HttpClientModule,
        NgxCaptchaModule,
        ReactiveFormsModule, FormsModule,
        MatDialogModule, MatButtonModule,
    ],
    providers: [ActiveJSService, CookieJSService, ContactUSService, MarqueeService, AlertService,
        GenericDataService, GlobalVariablesService, DeviceDetectorService],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
