<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <div class="page_link">
          <a href="#" routerLink="">Home</a>
          <a href="#" routerLink="/contact-us">Contact Us</a>
        </div>
        <h2>Contact Us</h2>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <!--================Contact Area =================-->
    <section class="contact_area p_120">
      <div class="container">

        <div *ngIf="SuccessMsg!=null && SuccessMsg!=''" class="alert alert-success" role="alert">
          <strong>{{SuccessMsg}}</strong>
        </div>

        <div *ngIf="ErrorMsg!=null && ErrorMsg!=''" class="alert alert-danger" role="alert">
          <strong>{{ErrorMsg}}</strong>
        </div>
        <div class="row">
          <div class="col-lg-3">
            <div class="contact_info">
              <div class="info_item">
                <i class="lnr lnr-home"></i>
                <h4>Lagos, Nigeria</h4>
                <!-- <p>Ebutte Metta East</p> -->
              </div>
              <div class="info_item">
                <i class="lnr lnr-phone-handset"></i>
                <h4><a href="tel:+2348165559818" rel="nofollow">+234 (0) 816-555-9818</a></h4>
                <p>Mon to Fri 9am to 6 pm</p>
              </div>
              <div class="info_item">
                <i class="lnr lnr-envelope"></i>
                <h4><a href="mailto:info@efuelite.com">info@efuelite.com</a></h4>
                <p>Send us your enquiries anytime!</p>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <form class="row contact_form" novalidate [formGroup]="ContactForm">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control" name="name" placeholder="Enter your name"
                    formControlName="UserName">
                </div>
                <div class="form-group">
                  <input type="email" class="form-control" name="email" placeholder="Enter email address"
                    formControlName="UserEmail">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" name="subject" placeholder="Enter Subject"
                    formControlName="UserSubject">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <textarea class="form-control" name="message" rows="1" placeholder="Enter Message"
                    formControlName="UserMessage"></textarea>
                </div>
              </div>

              <div class="col-md-12 text-right">
                <button class="btn submit_btn" (click)="SendMail()">Send Message</button>
              </div>
              <!-- <pre>{{ContactForm.value | json}}</pre> -->
            </form>
          </div>
        </div>
        <br />
        <div *ngIf="SuccessMsg!=null && SuccessMsg!=''" class="alert alert-success" role="alert">
          <strong>{{SuccessMsg}}</strong>
        </div>

        <div *ngIf="ErrorMsg!=null && ErrorMsg!=''" class="alert alert-danger" role="alert">
          <strong>{{ErrorMsg}}</strong>
        </div>
      </div>
      <div class="mapouter">
        <div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no"
            marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=efuelite solutions&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a
            href="https://kokagames.com/">FNF Mods</a></div>
        <style>
          .mapouter {
            position: relative;
            text-align: right;
            width: 100%;
            height: 400px;
          }

          .gmap_canvas {
            overflow: hidden;
            background: none !important;
            width: 100%;
            height: 400px;
          }

          .gmap_iframe {
            height: 400px !important;
          }
        </style>
      </div>
    </section>
    <!--================Contact Area =================-->
  </div>
</section>