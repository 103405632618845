import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as Constant from './Constant';
import { IMail } from '../Interfaces/imail';
import { IResp } from '../Interfaces/iresp';

@Injectable({
  providedIn: 'root'
})
export class ContactUSService {

  constructor(private http: HttpClient) { }
  SendMail(model: IMail): Observable<IResp> {
    try {
      let uri = Constant.UrlContactUS;
      return this.http
        .post<IResp>(uri, model);
    } catch (err) {
      console.log(err);
    }
  }
}
