<div>
  <h2 mat-dialog-title>EFUELITE</h2>
  <hr>
  <mat-dialog-content>
    <strong>{{data}}</strong>
  </mat-dialog-content>
  <hr>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" tabindex="-1" (click)="onCloseCancel()">CANCEL</button>
    &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
    <button mat-raised-button color="primary" tabindex="-1" (click)="onCloseConfirm()">CONFIRM</button>
  </mat-dialog-actions>
</div>