import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from '../Dialogs/dialog-confirm/dialog-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public dialog: MatDialog) { }
  showConfirm(message: string): Promise<boolean> {
    let dialogRef = this.dialog.open(DialogConfirmComponent, {
      data: message
    });
    return dialogRef.afterClosed().toPromise();
  }
}
