import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IGeneric } from '../Interfaces/igeneric';
import { GenericDataService } from './generic-data.service';
import { IDeviceProp } from '../Interfaces/idevice-prop';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariablesService {

  constructor() { }
  GenericList = [] as Array<IGeneric>;
  DeviceProp = {} as IDeviceProp;
  private Genders = new BehaviorSubject<any>([
    { value: 'M', text: 'Male' },
    { value: 'F', text: 'Female' }
  ]);
  Gender = this.Genders.asObservable();

  private Sorts = new BehaviorSubject<any>([
    { value: '1', text: 'Earliest' },
    { value: '0', text: 'Latest' }
  ]);
  Sort = this.Sorts.asObservable();

  //============================
  private NotifySorts = new BehaviorSubject<any>([
    { value: '0', text: 'Latest Notification(s)' },
    { value: '1', text: 'Earliest Notification(s)' },
    { value: '2', text: 'Notification Title Ascending' },
    { value: '3', text: 'Notification Title Descending' }
  ]);
  NotifySort = this.NotifySorts.asObservable();

  private NotifyViewTypes = new BehaviorSubject<any>([
    { value: '0', text: 'All Notification' },
    { value: '1', text: 'Unread Notification' },
    { value: '2', text: 'Read Notification' },
    { value: '3', text: 'Deleted Notification' }
  ]);
  NotifyViewType = this.NotifyViewTypes.asObservable();

  //============================

  private Dashboards = new BehaviorSubject<any>([
    { value: '0', text: 'General Dashboard' },
    { value: '1', text: 'File Dock' },
    { value: '2', text: 'Reports' },
    { value: '3', text: 'Transaction Monitoring' },
    { value: '4', text: 'Security' },
    { value: '5', text: 'Personal Settings' },
    { value: '6', text: 'Correspondence' },
    { value: '7', text: 'Task Manager' },
    { value: '8', text: 'Audit Trail' },
    { value: '9', text: 'Utility' },
    { value: '10', text: 'Data Panel' }
  ]);
  Dashboard = this.Dashboards.asObservable();
  //========================================
  private SearchCrit = new BehaviorSubject<string>('');
  SearchCrits = this.SearchCrit.asObservable();

  ChangeSearchCrit(message: string) {
    this.SearchCrit.next(message);
  }

  private UserState = new BehaviorSubject<number>(0);
  UserStates = this.UserState.asObservable();

  ChangeUserState(state: number) {
    this.UserState.next(state);
    //console.log('Current User State: ', state);
  }

  private CountryCodeLists = new BehaviorSubject<IGeneric[]>(this.GenericList);
  CountryCodeList = this.CountryCodeLists.asObservable();
  SetCountryList(result: IGeneric[]) {
    this.CountryCodeLists.next(result);
  }

  private DeviceP = new BehaviorSubject<IDeviceProp>(this.DeviceProp);
  DeviceProperty = this.DeviceP.asObservable();
  SetDeviceProperty(prop: IDeviceProp) {
    this.DeviceP.next(prop);
  }


  private DirChartLabels = new BehaviorSubject<Array<any>>([]);
  DirChartLabel = this.DirChartLabels.asObservable();
  private DirVolumeChartDatas = new BehaviorSubject<Array<any>>([{ data: [0], label: '' }]);
  DirVolumeChartData = this.DirVolumeChartDatas.asObservable();
  SetDirVolumeChartData(result) {
    this.DirVolumeChartDatas.next(result);
  }
  SetDirChartLabel(result) {
    this.DirChartLabels.next(result);
  }
}
