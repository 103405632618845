<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <div class="page_link">
          <a href="#" routerLink="">Home</a>
          <a href="#" routerLink="/careers">Careers</a>
        </div>
        <h2>Careers</h2>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 posts-list">
        <div class="section-top-border text-left">
          <!-- <h3 class="mb-30 title_color">Right Aligned</h3> -->
          <div class="row">
            <div class="col-md-9">
              <p class="text-left">The Efuelite culture -
                When you join Efuelite, the world becomes your workplace. You will feel the diversity and dynamics of our
                international business from day one.Explore opportunities to take your career to the next level.
                Whether you are a student, a graduate or an experienced professional, discover the impact you could
                make with a career at Efuelite.</p>
            </div>
            <div class="col-md-3">
              <img src="/src/assets/img/career/Career-1.PNG" alt="" class="img-fluid">
            </div>
          </div>
        </div>

        <div class="section-top-border text-left">
          <!-- <h3 class="mb-30 title_color">Right Aligned</h3> -->
          <div class="row">
            <div class="col-md-9">
              <p class="text-left">How you fit in -
                Whether you are a generalist, a specialist, newly-graduated or highly experienced in one of our
                business areas, you will find exciting opportunities within us.<a href="#" routerLink="/contact-us"><strong>
                    Find out where
                    you fit in</strong>.</a>
                Enhance your career development as an experienced professional with us and continue on your path to
                reach your full potential.
                Explore the wide range of opportunities that we have to offer top students and graduates, whether
                you're interested in working in a technical discipline, within commercial business, or for one of our
                corporate functions.

              </p>
            </div>
            <div class="col-md-3">
              <img src="/src/assets/img/career/Career-2.PNG" alt="" class="img-fluid">
            </div>
          </div>
        </div>

        <div class="section-top-border text-left">
          <!-- <h3 class="mb-30 title_color">Right Aligned</h3> -->
          <div class="row">
            <div class="col-md-9">
              <p class="text-left">Go with Efuelite Training Programme -
                Our programmes are designed to give you the best career foundation to succeed in a global industry
                impacting billions of people around the world every day. At Efuelite, we believe that passion drives
                your
                career while experiences shape it.<a href="http://codehub.efuelite.com" target="_blank"><strong>
                    Find out more...</strong>.</a></p>
            </div>
            <div class="col-md-3">
              <img src="/src/assets/img/career/Career-3.PNG" alt="" class="img-fluid">
            </div>
          </div>
        </div>
        <blockquote class="generic-blockquote">
          <span class="text-primary">CAREERS FAQS - </span><br />
          Looking for a quick overview of the opportunities available within Efuelite Group? Or is there something you
          are
          unsure about? Explore our Frequently Asked Questions and discover the answers you're searching for.
        </blockquote><br />
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne" data-toggle="collapse" data-target="#collapsePOne" aria-expanded="true"
              aria-controls="collapseOne">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  How do I apply to join Efuelite?
                </button>
              </h5>
            </div>
            <div id="collapsePOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                Please send a detailed CV with cover letter to <a href="tel:career@efuelite.com" target="_blank"><strong>career@efuelite.com</strong></a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo" data-toggle="collapse" data-target="#collapsePTwo" aria-expanded="true"
              aria-controls="collapseTwo">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePTwo"
                  aria-expanded="true" aria-controls="collapseTwo">
                  I can’t find a suitable job on your website, can I still apply?
                </button>
              </h5>
            </div>
            <div id="collapsePTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                We store all candidate details on a central database for three years, unless we receive a written
                request by an individual to delete their personal record. These records enable us to search against
                candidate profiles with specific skill sets, should we have specific recruitment needs in the future.
                Please send a detailed CV with cover letter to <a href="tel:career@efuelite.com" target="_blank"><strong>career@efuelite.com</strong></a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree" data-toggle="collapse" data-target="#collapsePThree"
              aria-expanded="true" aria-controls="collapseThree">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePThree"
                  aria-expanded="true" aria-controls="collapseThree">
                  Do I need a degree to work at Efuelite?
                </button>
              </h5>
            </div>
            <div id="collapsePThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                It will depend on the level and nature of the role in which you’re interested. If a degree is a
                requirement, it will usually be stated in the job description. However, a candidate who does not hold a
                degree may still be considered for one of our professional vacancies if they can demonstrate
                substantial relevant experience.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFour" data-toggle="collapse" data-target="#collapsePFour" aria-expanded="true"
              aria-controls="collapseFour">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePFour"
                  aria-expanded="true" aria-controls="collapseFour">
                  How long after applying can I expect to wait before hearing anything?
                </button>
              </h5>
            </div>
            <div id="collapsePFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
              <div class="card-body">
                You will receive an acknowledgement of receipt within 48 hours. You should then receive correspondence
                regarding a decision within the following two weeks.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingFive" data-toggle="collapse" data-target="#collapsePFive" aria-expanded="true"
              aria-controls="collapseFive">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePFive"
                  aria-expanded="true" aria-controls="collapseFive">
                  What do you mean by ‘Professional’?
                </button>
              </h5>
            </div>
            <div id="collapsePFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
              <div class="card-body">
                Our professional opportunities are for people with varying skills, experience and expertise. This
                includes all levels of seniority.
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingSix" data-toggle="collapse" data-target="#collapsePSix" aria-expanded="true"
              aria-controls="collapseSix">
              <h5 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapsePSix"
                  aria-expanded="true" aria-controls="collapseSix">
                  Do I need to have experience of working in the IT industry to join Efuelite?
                </button>
              </h5>
            </div>
            <div id="collapsePSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordionExample">
              <div class="card-body">
                It depends on the position you’re interested in. Many technical roles require you to have spent time in
                a similar job and environment, while others require less specific experience. Most of our commercial
                opportunities are based on generic business knowledge and so do not require experience in the IT
                industry.
              </div>
            </div>
          </div>
        </div>
      </div>
<!-- 
      <div class="col-lg-4">
        <app-right-side-quick-link></app-right-side-quick-link>
      </div> -->
    </div>

  </div>
</section>