import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActiveJSService } from './Services/active-js.service';
import { MarqueeService } from './Services/marquee.service';
import { GenericDataService } from './Services/generic-data.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IDeviceProp } from './Interfaces/idevice-prop';
import { GlobalVariablesService } from './Services/global-variables.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(private activeJSService: ActiveJSService, private marqueeService: MarqueeService,
    private sanitizer: DomSanitizer, private genericDataService: GenericDataService,
    private gVS: GlobalVariablesService) { }

  ngOnInit(): void {
    this.activeJSService.LoadCustomJS();
    this.genericDataService.DeviceInfo()
      .subscribe(r => {
        this.gVS.DeviceProperty.subscribe(r => { this.Device = r; });
      }, error => {
        this.gVS.DeviceProperty.subscribe(r => { this.Device = r; });
      }); 

  }
  ngAfterViewInit() {
    this.LoadMarquee();

    //this.LoadGoogleSearch();
  }
  title = 'Efuelite Group Limited';
  Marquee: string;
  gcsesearch: SafeHtml;
  Device = {} as IDeviceProp;
  Year: number = new Date().getFullYear();
  LoadGoogleSearch() {
    this.gcsesearch = this.sanitizer.bypassSecurityTrustHtml("<gcse:search></gcse:search>");

    var cx = '013641023013345787518:oakvvljt0kk';
    var gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
  }
  LoadMarquee() {
    this.marqueeService.Marquee_Get()
      .subscribe(r => {
        if (r.actionStatus) {
          this.Marquee = r.actionMessage;
        }
      })
  }

  options = {
    min: 8,
    max: 100,
    ease: 'linear',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: true,
    spinnerPosition: 'right',
    direction: 'ltr+',
    color: '#000099',
    thick: true
  };

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  onStarted() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  onCompleted() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
}
