import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { CareerComponent } from './career/career.component';
import { PolicyPrivacyAndCookieComponent } from './policy-privacy-and-cookie/policy-privacy-and-cookie.component';

const routes: Routes = [
  {
    path: '',//, redirectTo: 'about', pathMatch: 'full'
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'about-us',
    component: AboutUsComponent
  }, {
    path: 'contact-us',
    component: ContactComponent
  }, {
    path: 'careers',
    component: CareerComponent
  }, {
    path: 'policies/privacy-and-cookie',
    component: PolicyPrivacyAndCookieComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
