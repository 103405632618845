<!--================Home Banner Area =================-->
<section class="banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <div class="page_link">
          <a href="#" routerLink="">Home</a>
          <a href="#" routerLink="/about-us">About Us</a>
        </div>
        <h2>About Us</h2>
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->
<section class="blog_area single-post-area p_120">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 posts-list">
        <div class="ab_left_text">
          <h4>About Us <br />Our History</h4>
          <p>Efuelite Group is one of Africa’s most diversified business conglomerates with earned reputation
            for proficient business practices and products’ quality.</p>
        </div>
      </div>
      <!-- <div class="col-lg-4">
        <app-right-side-quick-link></app-right-side-quick-link>
      </div> -->
    </div>
  </div>
</section>