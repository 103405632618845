import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  UntypedFormGroup, UntypedFormControl, Validators
} from '@angular/forms';
import { IMail } from '../Interfaces/imail';
import { ContactUSService } from '../Services/contact-us.service';
import { CaptchaService } from '../Services/captcha.service';
import { AlertService } from '../Services/alert.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
  // ,
  // animations: [slideInOutAnimation],
  // host: { '[@slideInOutAnimation]': '' }
})
export class ContactComponent implements OnInit, AfterViewInit {

  constructor(private contactUSService: ContactUSService, private captchaService: CaptchaService,
    private alertService: AlertService) { }
  ContactForm: UntypedFormGroup;
  UserName: UntypedFormControl;
  UserEmail: UntypedFormControl;
  UserSubject: UntypedFormControl;
  UserMessage: UntypedFormControl;
  MailModel = {} as IMail;
  SuccessMsg: string;
  ErrorMsg: string;
  Processing: boolean;
  Token: string;
  ngOnInit() {
    this.CreateFormControl();
    this.CreateForm();
  }
  ngAfterViewInit() {
    this.GetToken();
  }
  GetToken() {
    this.captchaService.GetUserToken()
      .then(token => {
        this.Token = JSON.stringify(token);
        //console.log(this.Token);
        this.Token = this.Token.substr(1, this.Token.length - 2)
        //console.log(this.Token);
      });
  }
  SendMail() {
    this.alertService.showConfirm('Proceed to send message to Efuelite Group...')
      .then(UserResp => {
        if (UserResp) {
          this.ErrorMsg = '';
          this.SuccessMsg = '';
          if (this.ContactForm.valid) {

            this.MailModel.userEmail = this.ContactForm.controls.UserEmail.value;
            this.MailModel.userName = this.ContactForm.controls.UserName.value;
            this.MailModel.userSubject = this.ContactForm.controls.UserSubject.value;
            this.MailModel.userMessage = this.ContactForm.controls.UserMessage.value;
            this.MailModel.subsidiary = "Efuelite Group Limited";
            this.MailModel.captchaResponse = this.Token;
            this.contactUSService.SendMail(this.MailModel)
              .subscribe(r => {
                if (r.actionStatus) {
                  this.SuccessMsg = r.actionMessage + '. We\'ll get in touch shortly. Thanks so much ' + this.MailModel.userName;
                  this.ContactForm.reset();
                } else {
                  this.ErrorMsg = r.actionMessage;
                }
                this.GetToken();
              })
          } else {
            this.ErrorMsg = 'Please fill all required values';
          };
        }
      })

  };

  CreateFormControl() {
    this.UserName = new UntypedFormControl('', [Validators.required]);
    this.UserEmail = new UntypedFormControl('', [Validators.required]);
    this.UserSubject = new UntypedFormControl('', [Validators.required]);
    this.UserMessage = new UntypedFormControl('', [Validators.required]);
  };
  CreateForm() {
    this.ContactForm = new UntypedFormGroup({
      UserName: this.UserName,
      UserEmail: this.UserEmail,
      UserSubject: this.UserSubject,
      UserMessage: this.UserMessage
    });
  };
};
