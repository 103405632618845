import { Injectable, OnInit, AfterViewInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import * as Constant from './Constant';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICaptcha } from '../Interfaces/icaptcha';
@Injectable({
  providedIn: 'root'
})
export class CaptchaService implements OnInit, AfterViewInit {

  constructor(private reCaptchaV3Service: ReCaptchaV3Service, private http: HttpClient) { }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }

  GetUserToken = () => {
    return new Promise((resolve, reject) => {
      this.reCaptchaV3Service.execute(Constant.Captcha_Site_Key, 'efuelitecpg', (token) => {
        resolve(token)
      });

    });

  }

}