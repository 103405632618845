<!--================Home Banner Area =================-->
<section class="home_banner_area">
  <div class="banner_inner d-flex align-items-center">
    <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
      <div class="banner_content text-center">
        <h3>Efuelite <span> Group</span></h3>
        <p>Efuelite Group is one of Africa’s most diversified business conglomerates with earned reputation
          for proficient business practices and products’ quality.</p>
        <!-- <a class="black_btn" href="#services_areas">Discover How</a> -->
      </div>
    </div>
  </div>
</section>
<!--================End Home Banner Area =================-->

<!--================Services Area =================-->
<section class="services_area p_120" id="services_areas">
  <div class="container">
    <div class="main_title">
      <h2>Our Group Services</h2>
      <!-- <p>Lorem ipsum dolor sit amet, consecteturadipis icing elit,</p> -->
    </div>
    <div class="row services_inner">
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteSolutions_Logo_trans.png" alt="Efuelite Solutions LTD.">
          </span>
          <p>Business automation at it's peak at Efuelite Solutions. We offer Mobile App Development,
            Web App Development, Digital Marketing, IT Consultancy, Business Modelling, Training etc.<br />
            <a href="https://solutions.efuelite.com" target="_blank">
              See More at <span class="text-primary">https://solutions.efuelite.com</span>
            </a>
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteCodeHub_Logo_trans.png" alt="Efuelite Code Hub LTD.">
          </span>
          <p>At Efuelite Code Hub, we offer Certified Software Development Course, Software Automated Testing
            Course
            required to envision,
            design, build, test, and deploy industrial softwares.<br />
            <a href="https://codehub.efuelite.com" target="_blank">
              See More at <span class="text-primary">https://codehub.efuelite.com</span>
            </a>
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteMeals_Logo_trans.png" alt="Efuelite Meals LTD.">
          </span>
          <p>At Efuelite Meals, we undertake manufacturing, supply(wholesale, retail) of commercial and custom made
            drinks, fruit juice, pastry products, cakes etc. Standard train services.<br />
            <a href="https://meals.efuelite.com" target="_blank">
              See More at <span class="text-primary">https://meals.efuelite.com</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    <div class="row services_inner">
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteRidesLogo_trans.png" alt="Efuelite Rides LTD.">
          </span>
          <p>Efuelite Rides is a peer-to-peer ridesharing company offering smooth, fast and secure rides with world
            class comfort.<br />
            <!-- <a href="http://rides.efuelite.com">
              See More at <span class="text-primary">http://rides.efuelite.com</span>
            </a></p> -->
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteProductionLogo_trans.png" alt="Efuelite Production LTD.">
          </span>
          <p>At Efuelite Productions, we untake production of movies, music, sound tracks, jingles etc. We provisioned
            a world class studio to offer you the best.<br />
            <!-- <a href="http://production.efuelite.com">
              See More at <span class="text-primary">http://production.efuelite.com</span>
            </a></p> -->
        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteInitiativeLogo_trans.png" alt="Efuelite Initiative LTD.">
          </span>
          <p>Efuelite Initiative is a Non Governmental Organisation solely aimed at making the world better.
            We fund projects via our empowerment programmes.<br />
            <!-- <a href="http://initiative.efuelite.com">
              See More at <span class="text-primary">http://initiative.efuelite.com</span>
            </a></p> -->
        </div>
      </div>
    </div>
    <!-- <div class="row services_inner">
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteMallLogo_trans.png" alt="Efuelite Mall LTD.">
          </span>
          <p>Efuelite Mall is a mall which is poised to give
            you a wholesome shopping experience of international standard. We offer you a host of options when it
            comes to choosing the best product for you<br />

        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteBeautyLogo_trans.png" alt="Efuelite Beauty LTD.">
          </span>
          <p>Efuelite Beauty Salon offers services in nail grooming, natural hair care, makeup application, braiding,
            barbing, weave treatments, beauty care and hair supplies. Visit us today for an amazing experience. <br />

        </div>
      </div>
      <div class="col-lg-4">
        <div class="services_item">
          <span class="custom-icon">
            <img height="100px;" width="200px;" style="border-radius:20px;"
              src="../assets/img/icon/EfueliteFashionLogo_trans.png" alt="Efuelite Fashion LTD.">
          </span>
          <p>At Efuelite Fashion House we provide you with sales(wholesale, retail) of the best and affordable fashion
            wears(unisex) both custom/bespoke and foreign. We serve you via online and physical outlets.<br />

        </div>
      </div>
    </div> -->

  </div>
</section>