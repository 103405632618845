import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieJSService {

  constructor() { }
  setCookie(name: string, val: string, days: number) {
    const date = new Date();
    const value = val;

    // Set it expire in x days
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }
  isCookieSet(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return true;
    }
    return false;
  }
  deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }
}
